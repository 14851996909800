import "@hotwired/turbo-rails";
import { turboConfirmationDialog } from "../mixins/confirmationDialog";

import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

//Turbo.session.drive = false;
Turbo.config.drive.progressBarDelay = 500;
Turbo.config.forms.confirm = (message, formElement) => {
  turboConfirmationDialog(formElement.cloneNode(true));
};

const application = Application.start();
// Loads all controllers
const context = require.context("../controllers", true, /\.js$/);
application.load(definitionsFromContext(context));
