/* Import TinyMCE */
import tinymce from "tinymce";

/* Default icons are required. After that, import custom icons if applicable */
import "tinymce/icons/default";

/* Required TinyMCE components */
import "tinymce/themes/silver";
import "tinymce/models/dom";

/* Import a skin (can be a custom skin instead of the default) */
//import "tinymce/skins/ui/oxide/skin.css";

/* Import plugins */
//import "tinymce/plugins/advlist";
//import "tinymce/plugins/code";
//import "tinymce/plugins/emoticons";
//import "tinymce/plugins/emoticons/js/emojis";
import "tinymce/plugins/link";
//import "tinymce/plugins/lists";
//import "tinymce/plugins/table";

/* content UI CSS is required */
//import contentUiSkinCss from "tinymce/skins/ui/oxide/content.css";

/* The default content CSS can be changed or replaced with appropriate CSS for the editor content. */
//import contentCss from "tinymce/skins/content/default/content.css";

/* Initialize TinyMCE */
export function tinymceInit(config = {}) {
  // timeout because turbo isn't reliable on wanting to re-init things again
  setTimeout(() => {
    tinymce.init(
      Object.assign(
        {},
        {
          selector: ".tinymce",
          language: "et",
          language_url: "/tinymce-selfhost/et.js",
          promotion: false,
          height: 280,
          menubar: "",
          toolbar:
            "blocks fontsize | forecolor backcolor | bold italic underline strikethrough | removeformat",
          plugins: "link",
          color_cols: 5,
          skin_url: "/tinymce-selfhost/oxide",
          content_css: "/tinymce-selfhost/content/default/content.min.css",
          convert_unsafe_embeds: true
          //content_style: contentUiSkinCss.toString() + "\n" + contentCss.toString() Not possible with shakapacker. It cannot get those CSS as string
        },
        config
      )
    );
  }, 50);
}
