import "bootstrap-datepicker";
import "bootstrap-datepicker/dist/locales/bootstrap-datepicker.et.min.js";

const datePickerOptions = {
  clearBtn: true,
  weekStart: 1,
  maxViewMode: 2,
  autoclose: true,
  language: "et",
  todayHighlight: true,
  keyboardNavigation: false,
  orientation: "bottom",
  forceParse: false,
  format: {
    toDisplay: function (date) {
      return DayJS(date).format("DD.MM.YYYY");
    },
    toValue: function (date) {
      return date;
    }
  }
};

$.extend($.fn.datepicker.defaults, datePickerOptions);

window.initDatepicker = function () {
  document.querySelectorAll('[data-behaviour="datepicker"]').forEach(el => {
    if (el.parentElement.querySelector("input[data-is-server-input]")) {
      return; // If found, then it means it still has retained it and its just turbo:submit-end triggerig initDatepicker again
    }

    el.autocomplete = "off";
    let dateValue = DayJS(el.value, ["YYYY-MM-DD", "DD.MM.YYYY"]);

    function setDate(inputEl, date) {
      if (!date || date == "") {
        inputEl.value = null;
        return true;
      } else {
        let dateValue =
          date.constructor.name == "Date"
            ? DayJS(date)
            : DayJS(date, ["YYYY-MM-DD", "DD.MM.YYYY"], true);
        if (dateValue.isValid()) {
          inputEl.value = dateValue.format("YYYY-MM-DD");
        }
        return dateValue.isValid();
      }
    }

    let serverField = el.parentNode.querySelector(`input[type="hidden"]`);
    //if (serverField) return; // If element exists, then no need to create nor update input

    // Create seperate input where to store ISO date for the server
    serverField = document.createElement("input");
    serverField.type = "hidden";
    serverField.name = el.name;
    serverField.setAttribute("data-is-server-input", "true");

    if (Object.entries(el.dataset).length) {
      // Move (except behaviour) datasets to the new field
      Object.entries(el.dataset).forEach(([dataKey, dataVal]) => {
        if (dataKey == "behaviour") return;
        serverField.dataset[dataKey] = dataVal;
        delete el.dataset[dataKey];
      });
    }

    if (el.form) {
      serverField.addEventListener("input", () => {
        el.form.dispatchEvent(new Event("change"));
      });
    }

    setDate(serverField, dateValue);

    el.insertAdjacentElement("afterend", serverField);
    el.removeAttribute("name");

    let startDate = undefined;
    let endDate = undefined;

    if (serverField.dataset.dateStartDate) {
      startDate = serverField.dataset.dateStartDate;
    }
    if (serverField.dataset.dateEndDate) {
      endDate = serverField.dataset.dateEndDate;
    }

    $(el)
      .datepicker({
        startDate,
        endDate
      })
      .on("changeDate", ({ date }) => {
        if (setDate(serverField, date)) {
          serverField.dispatchEvent(new Event("input"));
        }
      })
      .on("input", ({ target }) => {
        if (setDate(serverField, target.value)) {
          serverField.dispatchEvent(new Event("input"));
        }
      });

    if (dateValue.isValid()) {
      $(el).datepicker("update", dateValue.toDate());
    }

    window.addEventListener(
      "turbo:before-cache",
      () => {
        el.name = serverField.name;
        serverField.remove();
      },
      { once: true }
    );
  });
};
