import "select2";
import "select2/dist/js/i18n/et.js";

$.fn.select2.defaults.set("theme", "bootstrap-5");
$.fn.select2.defaults.set("allowClear", true);
$.fn.select2.defaults.set("width", "100%");
$.fn.select2.defaults.set("dropdownAutoWidth", true);
$.fn.select2.defaults.set("language", "et");

export const defaultSelectAjaxOptions = {
  cache: true,
  dataType: "json",
  delay: 900, // wait 1 seconds before triggering the reques
  data: function (params) {
    return { term: params.term };
  },
  processResults: function (data) {
    return data;
  }
};

window.initSelect2 = function (specificEl, customOptions = {}) {
  function setup(select) {
    const options = {
      placeholder: select[0].attributes.placeholder?.value ?? "Vali",
      multiple: select.data("multiple"),

      ...customOptions
    };

    if (select.hasClass("select-ajax")) {
      options.ajax = Object.assign(defaultSelectAjaxOptions, {
        url: select.data("source")
      });

      const queryParams = select.data("query-params");
      if (queryParams) {
        const queryParamsToSend = {};
        queryParams.split("&").forEach(param => {
          const [key, val] = param.split("=");
          queryParamsToSend[key] = val;
        });
        options.ajax.data = function (params) {
          return { term: params.term, ...queryParamsToSend };
        };
      }

      options.placeholder = "Otsimiseks sisesta esimesed tähed";

      const minInputLen = select.data("minimum-input-length");
      options.minimumInputLength = minInputLen > 0 ? minInputLen : 3;
      if (select.data("allownew")) {
        // Allow manually entered text in drop down.
        options.createSearchChoice = function (term, data) {
          if (
            $(data).filter(function () {
              return this.text.localeCompare(term) === 0;
            }).length === 0
          ) {
            return { id: term, text: term };
          }
        };
      }
    }

    select.select2(options);

    // Clearing reopens the menu and forcefuly selects something else so quickly
    let clearing = false;
    select.on("select2:clearing", () => {
      // See turbo:before-cache for why
      Array.from(select[0].querySelectorAll(`option`)).forEach(
        x => (x.defaultSelected = false)
      );
      clearing = true;
    });
    select.on("select2:opening", e => {
      if (clearing) {
        clearing = false;
        e.preventDefault();
      }
    });

    select.on("select2:open", () => {
      // https://github.com/select2/select2/issues/5993#issuecomment-871662757
      let allFound = document.querySelectorAll(
        ".select2-container--open .select2-search__field"
      );
      allFound[allFound.length - 1].focus();
    });

    // See turbo:before-cache listener here in this file for why
    select.on("select2:unselect", ({ target, params }) => {
      target.querySelector(
        `option[value="${params.data.id}"]`
      ).defaultSelected = false;
    });

    if (select[0].form) {
      // Having "allowClear" option is triggering every selected item an seperate change event
      // Problem occures in events page, causing selected countries total = the amount of queries sent to server
      // Added 5ms delay to workaround it so it only sends 1 query
      $(select).on("change.select2", () => {
        select[0].form.dispatchEvent(new Event("change"));
      });
    }
  }

  if (specificEl) {
    setup($(specificEl));
  } else {
    $(".select2, [data-behaviour=select2]").each(function (i, evt) {
      setup($(evt));
    });
  }
};

document.addEventListener("turbo:before-cache", () => {
  // Remove or duplication/failure to show options again
  $(".select2, [data-behaviour=select2]").each(function (i, evt) {
    evt = $(evt);
    if (evt.hasClass("select2-hidden-accessible")) {
      evt.select2("destroy");

      // When user returns to cached page (browser back button), selected values don't reapper again unless page refresh by user
      // defaultSelected fixes the problem
      // Something is just off about <select> and browser
      const val = evt.val();
      if (!val) return;
      if (typeof val === "string") {
        if (evt[0].querySelector(`option[value="${val}"]`)) {
          evt[0].querySelector(`option[value="${val}"]`).defaultSelected = true;
        }
      } else {
        const vals = Array.from(val);
        evt[0].querySelectorAll(`option`).forEach(option => {
          option.selected = vals.includes(option.value);
          option.defaultSelected = vals.includes(option.value);
        });
      }
    }
  });
});
